/* :root {
    --app-primary-color: #357C3C; 
    --app-secondary-color: #03045E;
} */

:root {
    --app-primary-color: #0f0e0e;
    --app-secondary-color: #357C3C;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.finger {
    cursor: pointer;
}

.secondary-color {
    background-color: var(--app-primary-color);
    border: none;
}

.on-hover:hover {
    background-color: var(--app-secondary-color) !important;
}

.app-sec-color {
    background-color: var(--app-secondary-color);
}

.app-sec-text-color {
    color: var(--app-secondary-color);
}

.app-primary-color {
    background-color: var(--app-primary-color);
}

.app-pri-text-color {
    color: var(--app-primary-color);
}